<template>
  <div>
    <b-input 
      @input="update" 
      :value="name != null ? name : currentEditedEntry.name || currentEntry.name" 
      size="sm" 
      :formatter="formatter" 
      required>
    </b-input>
  </div>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";

export default {
  mixins: [DetailUpdateMixin],
  data() {
    return {
      name: null,
    }
  },
  
  methods: {
    update(name) {
      this.$store.dispatch(this.storeEditAction, {
        name: name,
      });
    },
    /**
     * @description limite le nom du produit à 50 caractères maximum
     */
    formatter(value) {
      return value.slice(0, 50);
    }
  },
  watch: {
    currentEditedEntry: {
      deep: true,
      handler: function() {
        this.name = this.currentEditedEntry.name
      }
    },
    mixedInput_composition: {
      deep: true,
      handler: async function() {
        this.name = this.currentEditedEntry.name;
      }
    }
  }
}
</script>
